<template>
  <div class="mt-10" :class="{ 'min-h-100vh': $route.path == '/company' }">
    <v-container v-show="show">
      <h2 class="f-20 mb-5">会社概要</h2>
      <div class="company_box relative-center">
        <div>
          <div class="th bold border-top text-left">
            <div>社名</div>
          </div>
          <div class="td text-left border-top">
            株式会社CREVo
          </div>
          <div class="clear" />
          <div >
            <div ref="addTh" class="th bold text-left relative">
              <div>本社所在地</div>
            </div>
            <div ref="addTd" class="td text-left">
              〒808-0025<br />福岡県北九州市若松区中川町4-6 CREVoビル
            </div>
            <div class="clear" />
          </div>
          <div>
            <div class="th bold text-left">
              <div>代表者</div>
            </div>
            <div class="td text-left">吉田暉石</div>
            <div class="clear" />
          </div>

          <div >
            <div class="th bold text-left">
              <div>連絡先</div>
            </div>
            <div class="td text-left">093-600-2040</div>
            <div class="clear" />
          </div>

          <div>
            <div class="th bold text-left">
              <div>WEBサイト</div>
            </div>
            <div class="td text-left">
              <a href="https://crevo-co.jp" target="blank">https://crevo-co.jp</a>
            </div>
            <div class="clear" />
          </div>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import moment from "moment";
import Vue from "vue";
Vue.prototype.moment = moment;

export default {
  data() {
    return {
      show: false,
    };
  },
  created() {
    // if (!this.Info.address || (this.Info.type == 1 && this.Station.length == 0) || !this.Info.starthh) {
    //     window.location.href = "/notfound";
    //     return false;
    // }
    this.show = true;
  },
  mounted() {
    if (window.innerWidth > 599) {
      this.$refs.addTh.style.height = this.$refs.addTd.clientHeight + "px";
      this.$refs.addTh2.style.height = this.$refs.addTd2.clientHeight + "px";
      this.$refs.jobTh.style.height = this.$refs.jobTd.clientHeight + "px";
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/company.scss";
</style>
