<template>
  <div class="blue-grey lighten-5 pt-10">
    <div>
      <v-row class="w1600 relative-center">
        <v-col cols="12" xl="6" lg="5" md="5" sm="12">
          <img
            :src="require('@/assets/img/company.webp')"
            class="relative company_img"
          />
        </v-col>
        <v-col cols="12" xl="6" lg="7" md="7" sm="12" class="text-left">
          <div class="bold text-center" >
            <div class="mb-8">
              <span class="f-30 f-28-sm f-18-xs noto9"
                >C<span class="f-orange">R</span>EVo</span
              >&nbsp;&nbsp;<span class="f-24 f-22-sm f-12-xs">の</span
              >&nbsp;&nbsp;<span class="f-29 f-27-sm f-16-xs">会社案内 </span>
            </div>
            <h2
              class="f-22 f-14-md f-16-sm f-14-xs opacity0 inline-block"
              :class="{ 'bottom-up': shows[0] }"
              ref="maind"
            >
              私たちが実践する<span class="disno-xxs">&nbsp;&nbsp;</span>
              <br class="dis-xxs" />
              <span class="f-32 f-24-md f-28-sm f-24-xs f-28-xxs f-orange"
                >2</span
              >トップ･マネジメント
            </h2>
            <div class="mt-16">
              <div
                class="opacity0 h3"
                :class="{ 'right-in': shows[1] }"
                ref="maind1"
              >
                <h3 class="f-18 f-16-xs f-12-xxs">
                  1．トップスキル・マネジメント
                </h3>
                <p
                  class="bold f-13 f-10-md f-12-sm f-10-xs f-08-xxs mt-6 t-left l-h-2 inline-block text-left"
                >
                  不動産取引に関する知識・技能・経験に基づく<br />
                  最高品質のマネジメントをご提供いたします
                </p>
              </div>
              <div
                class="opacity0 box1_0"
                :class="{ 'bottom-up': shows[2] }"
                ref="maind1s"
              >
                <Box1 class="mt-15" :Minds="mind1s" :No="0" />
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row
        class="w1500 relative-center opacity0"
        :class="{ 'bottom-up': shows[2] }"
        ref="maind1s"
      >
        <v-col cols="12" xl="6" lg="6" md="6" sm="12" v-for="i in 4" :key="i">
          <Box1 :Minds="mind1s" :No="i" />
        </v-col>
      </v-row>
    </div>
    <v-row class="w1400 relative-center">
      <v-col cols="12" xl="5" lg="5" md="5" sm="12" order="2"  order-xl="1" order-lg="1" order-md="1" >
        <div class="text-center">
          <div class="inline-block map_img">
            <img :src="require('@/assets/img/map.svg')" />
          </div>
        </div>
      </v-col>
      <v-col cols="12" xl="7" lg="7" md="7" sm="12" order="1"  order-xl="2" order-lg="2" order-md="2" >
        <div
          class="satei_box f-15 f-10-sm  bold f-orange relative-center text-left"
        >
          <img
            :src="require('@/assets/img/satei.png')"
            class="satei absolute filter-shadow disno-xxs"
          />
          <div class="satei_center">
          <div class="satei_text inline-block relative">
            <div class="inline-block fuchi">
              不動産のご相談・査定は<br />是非クレボにお任せください

              <div
                class="text-right f-12 f-10-xxxs f-blue mt-2 pointer"
                @mouseover="move"
                @mouseleave="leave"
              >
                <div class="inline-block" v-scroll-to="'#order'">
                  <span class="noto9 f-black"
                    >C<span class="f-orange">R</span>EVo</span
                  >に問い合わせる&nbsp;<span class="relative" ref="sankaku"
                    >&#9654;</span
                  >
                  <div class="blue_border mt-1 rounded-pill" />
                </div>
              </div>
            </div>
          </div>
          </div>
          <div class="absolute_x orange_border"></div>
        </div>
      </v-col>
    </v-row>

    <!-- <div class="relative mt-10">
      <div class="bg_rad absolute" ref="rad" />
      <div class="opacity0" :class="{ 'fade-in': shows[3] }">
        <v-row class="w1500 relative-center">
          <v-col cols="12" xl="6" lg="7" md="7" sm="12" class="text-left">
            <div class="mt-16 h3">
              <h3 class="f-18 f-16-xs f-12-xxs">
                2．リーズナブル・マネジメント
              </h3>
              <p
                class="bold f-13 f-12-md f-10-xs f-08-xxs mt-6 t-left l-h-2 inline-block text-left"
              >
                お客様が、最も得をする事業者としてクレボを選んで<br />
                いただけるよう、様々なサービスをご用意しています
              </p>
              <div class="box2_0">
                <Box2 class="mt-15 box2_0" :Minds="mind2s" :No="0" />
              </div>
            </div>
          </v-col>
          <v-col cols="12" xl="6" lg="5" md="5" sm="12">
            <div class="relative inline-block room4">
              <img :src="require('@/assets/img/room4.jpg')" />
              <div
                class="absolute r-10 t-10 bold f-white f-11 f-10-xxs f-09-xxxs fuchi_black"
                v-html="rooms[2]"
              />
            </div>
          </v-col>
        </v-row>
        <v-row class="w1500 relative-center">
          <v-col cols="12" xl="6" lg="6" md="6" sm="12" v-for="i in 2" :key="i">
            <div class="box2">
              <Box2 :Minds="mind2s" :No="i" />
            </div>
          </v-col>
        </v-row>
        <v-row class="w1500 relative-center">
          <v-col cols="12" xl="6" lg="6" md="6" sm="12">
            <div class="box2">
              <Box2 :Minds="mind2s" :No="3" />
            </div>
          </v-col>
          <v-col cols="12" xl="6" lg="6" md="6" sm="12">
            <div class="relative inline-block room5">
              <img :src="require('@/assets/img/room5.jpg')" />
              <div
                class="absolute r-10 t-10 bold f-white f-11 f-10-xxs fuchi_black"
                v-html="rooms[3]"
              />
            </div>
          </v-col>
        </v-row>
      </div>
    </div> -->

    <div class="relative mt-10">
      <v-row class="w1500 relative-center">
        <v-col cols="12" xl="6" lg="12" md="12" sm="12">
          <div class="relative inline-block room6">
            <img :src="require('@/assets/img/room6.png')" />
            <div
              class="absolute_x b-20 bold f-white f-11 fuchi_black"
              v-html="rooms[4]"
            />
          </div>
        </v-col>
        <v-col cols="12" xl="6" lg="12" md="12" sm="12" class="text-left">
          <div class="mt-16">
            <div ref="maind3" class="opacity0 h3_3">
              <h3 class="f-18 f-16-xs f-12-xxs">
                2．フレンドリー・マネジメント
              </h3>
              <p
                class="bold f-13 f-12-md f-10-xs f-08-xxs mt-6 t-left l-h-2 inline-block text-left"
              >
                和みのあるソフト・コミュニケーションを心がけ<br />
                いつでも、どこでも、誰とでも仲良く接します
              </p>
            </div>
            <div class="box3_0">
              <div class="relative inline-block">
                <img
                  :src="require('@/assets/img/icon3_0.png')"
                  class="absolute icon3_0 filter-shadow opacity0 disno-sm"
                  :class="{ 'left-in': shows[7] }"
                />
                <div class="opacity0" :class="{ 'bottom-up': shows[4] }">
                  <Box3 class="mt-4" :Minds="mind3s" :No="0" />
                </div>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row class="w1400 relative-center">
        <v-col cols="12" xl="6" lg="12" md="12" sm="12" v-for="i in 2" :key="i">
          <div class="relative inline-block">
            <div class="opacity0" :class="{ 'bottom-up': shows[4 + i] }">
              <img
                :src="require('@/assets/img/icon3_' + i + '.svg')"
                :class="[
                  'absolute icon3_' + i + ' filter-shadow opacity0 disno-sm',
                  { 'left-in': shows[7 + i] },
                ]"
                v-if="i == 2"
              />
              <Box3 :Minds="mind3s" :No="i" :class="'box3_' + i" />
            </div>
            <img
              :src="require('@/assets/img/icon3_' + i + '.svg')"
              :class="[
                'absolute icon3_' + i + ' filter-shadow opacity0 disno-sm',
                { 'bottom-up': shows[7 + i] },
              ]"
              v-if="i == 1"
            />
          </div>
        </v-col>
      </v-row>
      <div class="flip mt-10">
        <div class="flip-inner">
          <div class="front">
            <img
              class="thumbnail"
              :src="require('@/assets/img/crebou.png')"
              alt="コイン表"
            />
          </div>
          <div class="back">
            <img
              class="thumbnail thumbnail-hover"
              :src="require('@/assets/img/crebou.png')"
              alt="コイン裏"
            />
          </div>
        </div>
      </div>

      <div>クレ坊：by かおるちゃん作</div>
      <div>
        <Company />
        <div id="staff" class="pt-10" />
        <Staff />
      </div>
    </div>
    <div id="order" class="pb-10"/>
  </div>
</template>

<script>
import Box1 from "@/components/parts/box1.vue";
// import Box2 from "@/components/parts/box2.vue";
import Box3 from "@/components/parts/box3.vue";
import Company from "@/views/company.vue";
import Staff from "@/views/staff.vue";
import d from "@/d";

export default {
  data() {
    return {
      mind1s: [
        {
          title: "営業担当は皆、正規の国家資格保持者です",
          text: "クレボのスタッフは窓口・電話対応も含め、全てが不動産事業に携わるプロフェッショナルとして、宅地建物取引士及びファイナンシャルプランナーの国家資格を兼ね備えています",
        },
        {
          title: "不動産以外の関連実務にも精通しています",
          text: "不動産のみならず周辺知識においても日々、研鑽を磨くためのハードワークを課し、お客様に最新の有用情報をご提供できるよう努めております",
        },
        {
          title: "常に機転に富んだスピード対応を心がけています",
          text: "レスポンスの速さは、取引従事者として当たり前の所作に過ぎません。更に、正確さと機転を利かせることで、ワンランク上のフットワークを発揮いたします",
        },
        {
          title: "一語一語、丁寧なフレーズで物件を惹き立たせます",
          text: "大切な物件を、花嫁のように送り出すこともまた私たちの務め。案内文ひとつとして妥協せず、丸１日を費やし言葉を練る……他社では決してマネのできない、クレボ最大の強みこそがこの “フレーズ・ワーク” です",
        },
        {
          title: "査定は、どこに依頼しても同じではありません",
          text: "いかなる家屋にも、必ず加点ポイントが存在します。ＡＩ査定では、つい見落としがちな項目をしっかりとキャッチするのがクレボの目利き＝まごころ査定に他なりません",
        },
      ],
      mind2s: [
        {
          title: "遠慮は無用です 他社と存分に比べてください",
          text: "「契約は必ず専任の独占契約で」ここを頑として譲らない不動産事業者も存在しますが、クレボは必ずしも専任にこだわりません。むしろ、他社と比べてもらった方がクレボのお得感を認識していただけると考えています",
        },
        {
          title: "真のバリューアップを施します",
          text: "不動産というのは概ね、適正価格で取引されます。相場を大きく上回る価格で販売を試みても、買い手がつくことは稀です。「高く売る」ためには、その物件の適正価格を高める（<span class='f-blue'>バリューアップ</span>）より他ありませんが、改修費等の方が余計にかかるようでは、意味を為しません<br><br>クレボでは、独自のリーズナブル・マネジメントとして、無駄な資金を一切かけずに物件価値を底上げする真のバリューアップ・プランをご提案いたしております。ご興味の折は、お声がけくださいませ",
        },
        {
          title: "家１軒丸ごと 光触媒で抗菌コーティング",
          text: "クレボでは、売買仲介のご縁をいただいた全てのお客様に対し、光触媒抗菌コーティングの室内全面フル施工を無償で行っています<br><br>出口の見えないウイルス災禍をなんとか終息させたい、まずはクレボにできることから始めようと、利益を度外視した上で取組んでいます。正直……負担の大きいサービスですが&nbsp;そこはもう、やると決めた以上はとことん!!<br><br>光触媒コーティングは１回の施工で10年間、抗菌効果が持続し、以後のコストもメンテナンスも一切不要です。お客様には安心安全の10年間をお過ごしいただけます<br>※光触媒の詳細な効果については<a href='/hikari' target='_blank'>コチラ</a>でご確認ください",
        },
        {
          title: "担当者からの 最後の質問にお答えいただくと……",
          text: "「何をすれば、お客様に喜んでもらえるだろうか？」<br>100％の答えは未だ見つかっていませんが、少しでも正解に近付けるよう、クレボなりに考え抜いたプレゼントをご用意いたしております<br><br>つきましては、お取引の最後にお客様に対して質問をさせていただきますので、お答えを聞かせてください<br>一体、どんな質問？　それは最後のお楽しみということで。<br><br>青いネコ型ロボットのポケットから出てくるような、スゴイ物ではありませんが、ニヤリと笑っていただければ幸いです",
        },
      ],
      mind3s: [
        {
          title: "スマート・ユーモアを通じて商談を “笑談” へ",
          text: "いかに知識や経験が豊富で、良いサービスを提案できたとしても、最後は「人」。愛想に欠ける、つんとした態度で接していては、決まるものさえ頓挫してしまいます。<br><br>クレボの担当は皆、フレンドリーかつ紳士然と接します<br>持ち前の明るさと、決して調子に乗らない程よいスマート・ユーモアを交えながらお客様をエスコートいたします<br><br>「あなたが気に入ったから、紹介するよ」この言葉を聴きたくて、この言葉に触れる瞬間こそが、働く者にとっての大きな喜びです。<br>笑顔&#x1F600;でお会いしたく存じます",
        },

        {
          title: "同業者の方々ともフレンドリーな関係を求めます",
          text: "クレボと同じ不動産業に従事されている方々は、商売敵？<br>いえいえ、ちがいます。大切な同士であり、お仲間であると認識しており、良きビジネスパートナーとして可能な限り手を携え、歩み進んでいくことを強く望んでいます<br><br>時として、競合する場面もあるかもしれませんが、プイと背くことなく理解し合えるよう努めたいと思います<br>コラボレーション等、いつでもお気軽にお声がけください",
        },

        {
          title: "強いおすすめは 絶対にいたしません",
          text: "クレボは、お客様に向けた強要やゴリ押しの類は、主義としていたしませんが、熱意や自信が足りないわけではありません。どこまでも柔和なサポーターに徹することで、お客様に気持ちの良いお取引を行ってもらいたいのです<br><br>不動産屋さんのイメージって……　なんかこわい、ちょっと胡散臭い、やはりどこかとっつきにくい感じでしょうか<br>こうしたマイナスのイメージを抱いている御方々様はあと少しだけ、クレボのホームページを覗いてみてください<br><br>なるほど、人間味に溢れた不動産屋だと確認いただけた後に弊社をご指名・ご用命いただければ幸いでございます",
        },
      ],
      rooms: [
        "インテリアと化したハンモック",
        "気分を変えてフレーズ・ワーク",
        "コーヒー・紅茶など&nbsp;&nbsp;いつでもどうぞ",
        "やや&nbsp;ガチな商談スペース",
        "笑談(^^)v の始まり始まり",
      ],
      shows: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      sc01: 1,
      sc02: 1,
      sc03: 1,
      sc04: 1,
      sc05: 1,
    };
  },

  props: {
    ScroY: Number,
  },

  components: {
    Box1,
    // Box2,
    Box3,
    Company,
    Staff,
  },

  async mounted() {

    if (window.innerWidth > 1263) {
      const large = Math.max(
        document.getElementById("box1_1").offsetHeight,
        // document.getElementById("box1_2").offsetHeight,
        document.getElementById("box1_3").offsetHeight,
        document.getElementById("box1_4").offsetHeight
      );
      for (let i = 1; i <= 4; i++) {
        document.getElementById("box1_text_" + i).style.marginTop =
          (large - document.getElementById("box1_" + i).offsetHeight) / 2 +
          "px";
        document.getElementById("box1_" + i).style.height = large + "px ";
      }

      // const large2 = Math.max(
      //   document.getElementById("box2_1").offsetHeight,
      //   document.getElementById("box2_2").offsetHeight
      // );
      // for (let i = 1; i <= 2; i++) {
      //   document.getElementById("box2_text_" + i).style.marginTop =
      //     (large2 - document.getElementById("box2_" + i).offsetHeight) / 2 +
      //     "px";
      //   document.getElementById("box2_" + i).style.height = large2 + "px ";
      // }

      const large3 = Math.max(
        document.getElementById("box3_1").offsetHeight,
        document.getElementById("box3_2").offsetHeight
      );
      for (let i = 1; i <= 2; i++) {
        document.getElementById("box3_" + i).style.height = large3 + "px ";
      }
    } else if (window.innerWidth < 960) {
      this.sc01 = 0;
      this.sc02 = 0;
      this.sc03 = 0;
      this.sc04 = 0;
      this.sc05 = 0;
      this.shows = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1];
      // this.$refs.rad.classList.add("rad");
      this.$refs.maind3.classList.add("left-in");
    }
    if (location.hash   == "#company") {
      this.sc01 = 0;
      this.shows[0] = 1;
      this.sc02 = 0;
      this.shows[1] = 1;
      this.sc03 = 0;
      this.shows[2] = 1;
      this.shows.splice();
    }
  },
  methods: {
    move() {
      this.$refs.sankaku.classList.add("move");
    },
    leave() {
      this.$refs.sankaku.classList.remove("move");
    },
    async scroll01() {
      if (
        window.innerHeight + this.ScroY >
          this.$refs.maind.getBoundingClientRect().top * 3 &&
        this.sc01
      ) {
        this.sc01 = 0;
        this.shows[0] = 1;
        this.shows.splice();
      }
    },
    async scroll02() {
      if (
        window.innerHeight + this.ScroY >
          this.$refs.maind1.getBoundingClientRect().top * 3 &&
        this.sc02
      ) {
        this.sc02 = 0;
        this.shows[1] = 1;
        this.shows.splice();
      }
    },
    async scroll03() {
      if (
        window.innerHeight + this.ScroY >
          this.$refs.maind1s.getBoundingClientRect().top * 3 &&
        this.sc03
      ) {
        this.sc03 = 0;
        this.shows[2] = 1;
        this.shows.splice();
      }
    },
    async scroll04() {
      if (
        window.innerHeight + this.ScroY >
          this.$refs.rad.getBoundingClientRect().top * 4 &&
        this.sc04
      ) {
        this.sc04 = 0;
        this.$refs.rad.classList.add("rad");
        await d.sleep(1200);
        this.shows[3] = 1;
        this.shows.splice();
      }
    },
    async scroll05() {
      if (
        window.innerHeight + this.ScroY >
          this.$refs.maind3.getBoundingClientRect().top * 5 &&
        this.sc05
      ) {
        this.sc05 = 0;
        this.$refs.maind3.classList.add("left-in");
        for (let i = 0; i < 6; i++) {
          await d.sleep(500);
          this.shows[4 + i] = 1;
          this.shows.splice();
        }
      }
    },
  },
  watch: {
    ScroY() {
      this.scroll01();
      this.scroll02();
      this.scroll03();
      // this.scroll04();
      this.scroll05();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/top/company.scss";
</style>