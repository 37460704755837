<template>
  <div class="box1 w700 inline-block relative" :id="'box1_'+No">
      <div class="blue-grey lighten-5 absolute erase_box z-1"/>
    <img :src="require('@/assets/img/icon1_'+No+'.svg')" :class="'icon'+No+' absolute z-2'" />
    <div class="bold relative z-2">
      <div class="f-blue f-12 f-11-lg  f-09-xs f-11-xxs mb-2 text-center box_title"
      v-html="Minds[No].title"
      />
     <div v-html="Minds[No].text" class="text-left f-09-md" :id="'box1_text_'+No"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    Minds: Array,
    No: Number,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/parts/box1.scss";
</style>