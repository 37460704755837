<template>
  <div>
    <div>
    
      <!-- <People :MenuLoading="MenuLoading" :Loading2="Loading2"/> -->
      <Front :ScroY="scroY" :MenuLoading="MenuLoading"  :Loading2="Loading2"/>
      <Company :ScroY="scroY" />
      <Order />
    </div>
  </div>
</template>

<script>
import Front from "@/components/top/front.vue";
import Company from "@/components/top/company.vue";
// import People from "@/components/top/people.vue";
import Order from "@/components/parts/order.vue";
import Scroll from "@/mixins/scroll";

export default {
  mixins: [Scroll],
      props: {
    MenuLoading:Boolean,
    Loading2:Number
  },
  components: {
    Front,
    Company,
    Order,
  },
};
</script>
